import * as types from './Types'

const initailState = {
    AllMake: [],
    AllModel: [],
    AllVariantVahicle: [],
    AllActiveFuel: [],
    AllVariant: [],
    BasedOnModel: [],
    ActiveMake: [],

    addMake: {},
    updateMake: {},
    deleteMake: {},

    modelAdded: {},
    modelUpdated: {},
    modelDeleted: {},

    addVariant: {},
    variantUpdate: {},
    deleteVariant: {},
    loading: true
}

export const makeModelReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_MAKE:
            return {
                ...state,
                AllMake: payload,
                loading: false
            }
        case types.ADD_MAKE:
            return {
                ...state,
                addMake: payload,
                loading: false
            }
        case types.UPDATE_MAKE:
            return {
                ...state,
                updateMake: payload,
                loading: false
            }
        case types.DELETE_MAKE:
            return {
                ...state,
                deleteMake: payload,
                loading: false
            }
        // *************************************************************** Model
        case types.GET_ALL_MODEL:
            return {
                ...state,
                AllModel: payload,
                loading: false
            }
        case types.ADD_MODEL:
            return {
                ...state,
                modelAdded: payload,
                loading: false
            }
        case types.UPDATE_MODEL:
            return {
                ...state,
                modelUpdated: payload,
                loading: false
            }
        case types.DELETE_MODEL:
            return {
                ...state,
                modelDeleted: payload,
                loading: false
            }
        // *************************************************************** Variant
        case types.GET_ALL_VARIANT:
            return {
                ...state,
                AllVariant: payload,
                loading: false
            }

        case types.GET_ALL_VARIANT_VEHICLE:
            return {
                ...state,
                AllVariantVahicle: payload,
                loading: false
            }
        case types.GET_ALL_ACTIVE_FUEL:
            return {
                ...state,
                AllActiveFuel: payload,
                loading: false
            }

        case types.ADD_VARIANT:
            return {
                ...state,
                addVariant: payload,
                loading: false
            }
        case types.UPDATE_VARIANT:
            return {
                ...state,
                variantUpdate: payload,
                loading: false

            }
        case types.DELETE_VARIANT:
            return {
                ...state,
                deleteVariant: payload,
                loading: false
            }
        // ******************************************based on

        case types.GET_ALL_MODEL_BASED:
            return {
                ...state,
                BasedOnModel: payload,
                loading: false
            }
        case types.GET_ALL_ACTIVE_MAKE:
            return {
                ...state,
                ActiveMake: payload,
                loading: false
            }

        default:
            return state
    }
}

export default makeModelReducers