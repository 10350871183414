import * as types from './type'

const initialState = {
    AllSlabs: [],
    selectedSlab: {},
     loading: true
}
export const posPayoutReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {

        case types.GET_ALL_SLABS: return {
            ...state,
            AllSlabs: payload,
            loading: false
        }
      
        case types.STORED_SELECTED_SLAB: return {
            ...state,
            selectedSlab: payload,
            loading: false
        }

        default:
            return state
    }
}
export default posPayoutReducers